@use "styles/base";

.photo {
  display: inline-block;
}

.img {
  height: 500px;
}

.title {
  text-align: center;
  font-size: 1.2em;
  line-height: 1.8em;
  color: base.$color-primary;
}

@use "styles/common";

.material-box {
  padding: 0.5em 0;

  .material {
    max-width: 500px;
    max-height: 500px;
  }

  .material {
    max-width: 500px;
    max-height: 500px;
  }

  .video {
    width: 500px;
    max-width: 100%;
  }
}

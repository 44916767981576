$color-primary: #1890ff;

// $color-primary: darken(
//   $color: #1890ff,
//   $amount: 30,
// );

// $color-primary: darken(
//   $color: #1890ff,
//   $amount: 0,
//   //   $amount: 20,
// );

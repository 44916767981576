.wrapper {
  background: lightgrey;
  // background: #ddd;
  min-height: 100vh;
  width: 100%;
}

.main {
  width: 90%;
  max-width: 1008px;

  //   width: 1008px;
  //   width: 100%;
  margin: 0 auto;

  .title {
    text-align: center;
    padding-top: 0.8em;
  }

  .steps {
    width: 860px * 1.1;
    max-width: 100%;
    margin: 0 auto;
  }

  .content {
    margin: 2em auto 0;
    padding: 1.5em 3em 3em;
    width: 860px;
    max-width: 100%;
    background-color: #d0d0d0;
    background-color: #ddd;
    // background-color: darken($color: lightgrey, $amount: 15);
    // background: lightgrey;
    .loading {
      margin-top: 3em;
    }
  }
}

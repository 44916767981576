.progress {
  border: 1px dashed lightblue;
  margin-top: 2px;
  /* width: 200px; */
  height: 14px;
  margin-bottom: 10px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress-bar {
  background-color: rgb(92, 184, 92);
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.14902) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.14902) 50%,
    rgba(255, 255, 255, 0.14902) 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
  box-shadow: rgba(0, 0, 0, 0.14902) 0px -1px 0px 0px inset;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  display: block;
  float: left;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  transition-delay: 0s;
  transition-duration: 0.6s;
  transition-property: width;
  transition-timing-function: ease;
  /* width: 266.188px; */
}

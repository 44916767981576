@use "styles/base";

.error {
  margin-left: 0.5em;
}

.hint {
  margin-left: 0.5em;
  color: base.$color-primary;
}

.form-content {
  width: 100%;

  .select {
    width: 20em;
  }
}

.buttons {
  margin: 2em auto 0;
}

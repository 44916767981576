.closed {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  background-color: white;
}

.main {
  width: 420px;
  margin: 10em auto 0;

  .title-row {
    padding: 3em 0;
    width: 300px;
    margin: 0 auto;

    .title {
      padding-left: 1em;
    }
  }

  li {
    line-height: 2;
  }

  .wechat-code {
    padding: 0.5em 0;
    width: 12em;
  }

  .close {
    text-align: right;
  }
}

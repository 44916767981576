@use "styles/base";

.video-box {
  width: 600px;
}

// .content {
//   width: 600px;
//   //   height: 337.5px;
// }

.video {
  width: 100%;
  max-width: 100%;
  background-color: #ddd;
}

.title {
  text-align: center;
  font-size: 1.2em;
  line-height: 1.8em;
  color: base.$color-primary;
}

@use "styles/common";

.uploading {
  display: inline-block;
  margin-top: 1em;

  .progress {
    margin-top: 2em;
  }

  .bar {
    width: 100%;
  }
}

@use "styles/base";
@use "styles/common";

.letter {
  a {
    color: base.$color-primary;
  }
}

.photo-box {
  margin-top: 1em;

  .progress {
    margin-top: 2em;
  }

  .bar {
    width: 300px;
  }
}

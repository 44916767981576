@use "styles/common";

.row {
  display: flex;
  flex-direction: row;
  gap: 2em;
}

.uploading {
  display: inline-block;
  margin-top: 1em;

  .progress {
    margin-top: 2em;
  }

  .bar {
    width: 100%;
  }
}

.login {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  background-color: white;
}

.login-form {
  width: 420px;
  margin: 10em auto 0;
}

.title-row {
  padding: 3em 0;
  width: 300px;
  margin: 0 auto;

  .title {
    padding-left: 1em;
  }
}

.main {
  border: 2px dashed lightblue;
  width: 420px;
  margin: 0 auto;
  padding: 1.25em;
}

.note {
  width: 420px;
  margin: 2em auto 0;
}
